import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0029 from '../../../components/molecules/columnDetailInner0029'
import UsefulFunctionLayout021 from '../../../components/molecules/usefulFunctionLayout021'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0029 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title:
        '中小企業も対象？時間外労働の上限時間や割増賃金率の引き上げに注意！',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="中小企業も対象？時間外労働の上限時間や割増賃金率の引き上げ"
        description="2010年に労働基準法が改正され、法律で定められた割増賃金率が引き上げられています。2023年4月以降は中小企業も対象となりますので注意が必要です。"
        ogUrl="https://kintaicloud.jp/column/details/C0029/"
        ogType="article"
        ogTitle="中小企業も対象？時間外労働の上限時間や割増賃金率の引き上げ"
        ogDescription="2010年に労働基準法が改正され、法律で定められた割増賃金率が引き上げられています。2023年4月以降は中小企業も対象となりますので注意が必要です。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0029.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0029 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout021 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0029

export const pageQuery = graphql`
  query C0029 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
