import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0029 = () => (
  <Wrapper>
    <ColumnH1 label="中小企業も対象？時間外労働の上限時間や割増賃金率の引き上げ" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img src="/images/column/details/c0029.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        長時間労働問題に対応するため、昨今では時間外労働に関するさまざまな法改正が行われています。特に時間外労働の上限時間や、割増賃金率の引き上げについては、会社にとっても社員にとっても非常に重要な事項でありながら、数字が多く分かりづらい一面もあります。
        <br />
        今回は、時間外労働にまつわる上限時間、そして中小企業もいずれ義務化対象となる割増賃金率の引き上げの内容について、順を追って解説をしていきましょう。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">懸念される「長時間労働」問題</a>
          </li>
          <li>
            <a href="#toc2">時間外労働の限度とは</a>
          </li>
          <li>
            <a href="#toc3">割増賃金率引き上げの内容とは</a>
            <ul>
              <li>
                <a href="#toc3-1">時間外労働</a>
              </li>
              <li>
                <a href="#toc3-2">休日労働</a>
              </li>
              <li>
                <a href="#toc3-3">深夜労働</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc4">中小企業における割増賃金率引き上げ義務化</a>
          </li>
          <li>
            <a href="#toc5">まとめ</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>懸念される「長時間労働」問題</h1>
      </div>
      <p>
        企業で働く社員の残業過多や休日出勤などの長時間労働に関する問題が社会現象化してから久しい状況が続いています。国ではワーク・ライフ・バランスや働き方改革を打ち出し、各企業には労働者が健康を保ちながらプライベートな時間を確保しながら、安心して働くことができるような環境づくりが求められています。
        <br />
        <br />
        これを後押しする施策として、労働基準法が改正される運びとなりました。具体的には、時間外労働、いわゆる
        <span>残業時間の限度となる基準が見直され</span>
        、また割増賃金率が引き上げられることで、より長時間労働に対する規制が強まる状況となりました。
        <br />
        なお、時間外労働に関する改正が行われたと同時に、
        <span>有給休暇の時間単位付与も認められる</span>
        ことになりました。これにより、より総括的に労働者が健康で安全に働くことができるような環境づくりを後押ししています。
      </p>
      <div id="toc2">
        <h1>時間外労働の限度とは</h1>
      </div>
      <p>
        時間外労働とは、一日あたり8時間、一週間あたり40時間までを上限とする法定労働時間を超えて労働をすることをいいます。なお、この時間外労働をさせる場合は、
        <span>36協定の締結や届け出が義務づけられている</span>
        3点にも注意が必要です。
        <br />
        <br />
        本来ならば、時間外労働はあくまでもやむを得ない臨時の状況となった場合に行われるべきものであり、必要最小限の時間帯で収めることが求められています。
        <br />
        しかし、企業の中には時間外労働が恒常化しており、経営者も社員もその矛盾に気づかないまま過ぎていく場合もみられることが現状です。
        <br />
        <br />
        このような状況を打破するため、時間外労働の限度基準が定められています。
        <br />
        具体的な数値としては、<span>1ヶ月あたり45時間、一年あたり360時間</span>
        です。これを超えて時間外労働をさせる場合は、よほどの臨時的な特別事情がある場合に限られます。
        <br />
        さらに、臨時的な特別事情が発生した場合でも、これ以上は時間外労働をさせてはいけないという上限時間が定められています。
        <br />
        <br />
        ・時間外労働は、年720時間以内に収める
        <br />
        ・時間外労働と休日労働の合計は、月100時間未満に収める
        <br />
        ・時間外労働と休日労働の合計は、「2､3,4,5,6ヶ月平均値」がそれぞれ1ヶ月あたり80時間以内に収める
        <br />
        ・時間外労働が1ヶ月あたり45時間を超えることができる月は、一年あたり6ヶ月を限度とする
        <br />
        <br />
        この限度時間を超えて時間外労働をさせた場合は、6ヶ月以下の懲役刑または30万円以下の罰金刑に処せられる場合があるため、注意をしましょう。
      </p>
      <Introduction001 />
      <div id="toc3">
        <h1>割増賃金率引き上げの内容とは</h1>
      </div>
      <p>
        時間外労働の上限時間について理解をいただいたところで、ここからの項目では割増賃金率、いわゆる残業代の計算法について説明をしていきます。
        <br />
        <br />
        2010年に労働基準法が改正され、法律で定められた割増賃金率は、時間外労働の時間にあわせて次のように引き上げられています。
      </p>
      <div id="toc3-1">
        <h2>時間外労働</h2>
      </div>
      <p>
        ①法定労働時間内の残業：割増賃金率を加算する必要はありません
        <br />
        ②法定労働時間を超えた時間外労働：25％以上
        <br />
        ③1ヶ月あたり45時間、一年あたり360時間の限度時間を超えた時間外労働：25％以上（25％を超えた割増賃金率を設定するよう推奨）
        <br />
        ④1ヶ月あたり60時間を超えた時間外労働：50％以上
      </p>
      <div id="toc3-2">
        <h2>休日労働</h2>
      </div>
      <p>週に1日の法定休日を超えて働かせた場合：35％以上</p>
      <div id="toc3-3">
        <h2>深夜労働</h2>
      </div>
      <p>
        夜10時～明け方5時までの間に働かせた場合：25％以上
        <br />
        <br />
        なお、④の1ヶ月あたり60時間を超えた時間外労働をさせた場合は、事業場内であらかじめ労使協定を締結することで、引き上げられた割増賃金率分の支払の代わりに、有給休暇を与えることができるという
        <span>「代替休暇制度」</span>を導入することができます。
      </p>
      <div id="toc4">
        <h1>中小企業における割増賃金率引き上げ義務化</h1>
      </div>
      <p>
        先ほどの項目で述べた2010年の法改正における割増賃金率の引き上げについては、これまでは大企業に比べ規模の小さい中小企業では猶予されていました。
        <br />
        しかし、2023年4月以降は、この割増賃金率引き上げに関する猶予期間が終了することから、
        <span>中小企業も適用対象</span>となります。
        <br />
        中小企業の経営者においては、「まだ先のことだ」と安心していると、あっという間に時が過ぎて行くことになります。業務内容の効率化や新たな人材採用・育成など、今のうちから労働者の時間外労働を削減させるような取り組みを検討すると良いでしょう。
      </p>
      <div id="toc5">
        <h1 className="read">まとめ</h1>
      </div>
      <p>
        国による時間外労働の限度時間設定や割増賃金率の引き上げなどの施策は、中小企業を含めたすべての企業に対し、働き方改革に本格的に取り組む時期に来たというメッセージともいえます。
        <br />
        コロナ禍の影響により、リモートワークによる在宅勤務体制も拡大し、社員の働き方も以前と比較すると
        <span>多様化</span>
        しているのが現状です。まずは、現在の就労体制を見直すことから始めてみてはいかがでしょうか。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0029
